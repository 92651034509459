.total {
    background-color: #272525;
    border-radius: 5px;
    padding: 10px;
    margin: 20px 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total p:first-child {
    display: flex;
    align-items: center;
    gap: 5px;
}

.total p:first-child span {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #01D501;
}