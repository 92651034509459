.head {
    padding: 20px 10px;
    background-color: #3A3737;
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
}

.odd {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin: 10px 0 30px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.grid > .full {
    grid-column: span 2;
}

.calculateBtn {
    width: 130px;
    height: 50px;
    margin: 20px auto 0 auto;
}

.content {
    padding: 0 10px 30px;
}

.nextHash {
    margin: 10px 0 0;
}