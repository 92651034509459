.head {
    height: 55px;
    box-shadow: 0 0 10px 0 #0000004D;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.balance {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #1D1717;
    border-radius: 5px;
    height: 40px;
    padding-left: 10px;
    margin-left: auto;
    width: 100%;
}

.balance .btn {
    height: 100%;
    width: 80px;
    font-size: 14px;
    font-weight: 500;
    margin-left: auto;
}

.content {
    padding: 30px 10px;
}