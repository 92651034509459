.popup {
    padding: 20px;
}

.title {
    font-size: 18px;
    text-align: center;
}

.label {
    width: 100px;
    height: 50px;
    border-radius: 100px;
    border: 5px solid #272525;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}