.control {
    padding: 10px 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.control > button {
    margin-bottom: 10px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.autobet {
    position: relative;
}

.autobet .checkbox {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: row-reverse;
}

