.table {
    width: 100%;
    font-size: 14px;
}

.table thead td {
    padding: 8px 5px;
    font-weight: 700;
}

.table td {
    padding: 5px;
    text-align: center;
}

.table tbody tr:nth-child(odd) td {
    background-color: #373434;
}

.table tbody tr:nth-child(odd) td:first-child {
    border-radius: 5px 0 0 5px;
}

.table tbody tr:nth-child(odd) td:last-child {
    border-radius: 0 5px 5px 0;
}


.table svg path {
    fill: #01D501;
}
