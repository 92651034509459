.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #01D501 61.77%, #FFFFFF 140.69%);
    border-radius: 5px;
    height: 60px;
    width: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    transition: all .2s ease;
}

.btn:active {
    box-shadow: inset 0 4px 2px 0 rgba(0, 0, 0, 0.3);
}

.btn:disabled {
    background: linear-gradient(180deg, #494545 0%, #272525 100%);
    color: #626060;
}

.btn_secondary {
    background: linear-gradient(180deg, #FF9C00 61.77%, #FFFFFF 140.69%);
}
