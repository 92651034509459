.switch {
    background-color: #272525;
    width: 100%;
    border-radius: 100px;
    padding: 5px;
    display: flex;
    gap: 5px;
}

.switch__item {
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    transition: all .3s ease;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.switch__item input {
    display: none;
}

.switch__item:has(>input:checked) {
    background-color: #494545;
}