.footer {
    padding: 0 26px;
    height: 70px;
    background-color: #272525;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.btn {
    transition: all .3s ease;
}

.btn:last-child {
    margin-left: auto;
}

.btn_active svg path {
    fill: #01D501;
}