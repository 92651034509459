.wrapper {
    width: 100%;
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.field {
    padding: 3px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #534F4F;
    display: flex;
    align-items: center;
    position: relative;
}

.field .info {
    position: absolute;
    bottom: -20px;
    background-color: #000;
    border-radius: 3px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 12px;
    padding: 3px 5px;
}

.field:has(>.input input:disabled) {
    pointer-events: none;
}

.input {
    width: 100%;
    height: 44px;
    background-color: #272525;
    padding: 0 10px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.input input {
    background-color: transparent;
    width: 100%;
    user-select: none;
}

.input .controls {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.input:has(> input:disabled) {
    color: #525151;
}

.field .btn {
    min-width: 60px;
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.field .btn:active {
    color: #01D501;
}

.field .btn:not(:first-of-type) {
    border-left: 2px solid #25262B80;
}