.wrapper {
    background-color: #272525;
    height: 330px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.results {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

.results__item {
    width: 50px;
    height: 30px;
    border-radius: 5px;
    background-color: #3A3737;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.results__item_green {
    background-color: #01D501;
}

.results__item_all {
    width: 42px;
    border-radius: 50%;
    background-color: #534F4F;
}

.chart {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.info {
    height: 76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.info > *:not(canvas) {
    position: relative;
    z-index: 1;
}

.odd {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
}

.odd_crashed {
    color: #F40808;
}

.message {
    height: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.crashed {
    width: 110px;
    background-color: #494545;
    color: #FF3333;
    font-weight: 700;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.starting {
    width: 160px;
    background-color: #494545;
    border-radius: 3px;
    position: relative;
}

.starting__progressbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background: linear-gradient(90deg, rgba(73, 69, 69, 0) -51.25%, #FF9C00 6.74%, rgba(73, 69, 69, 0) 78.61%);
    transition: all 1s linear;
}

.starting > span {
    position: relative;
    z-index: 11;
}

.cashouts {
    position: absolute;
    right: 0;
    bottom: 40px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cashouts__item {
    font-size: 12px;
    border-radius: 100px;
    background-color: #3A3737;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    padding: 0 15px;
    gap: 5px;
}

.cashouts__item span {
    color: #01D501;
}

.win {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all .3s ease;
    opacity: 0;
    pointer-events: none;
}

.win_active {
    opacity: 1;
    pointer-events: all;
}

.win > p {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #FF9C00;
}

.win span {
    color: #fff;
}

.win img {
    max-width: 100%;
    margin-top: -25px;
}
