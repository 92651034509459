.checkbox input {
    display: none;
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.control {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    border-radius: 100px;
    background-color: #534F4F;
    box-shadow: 0 2px 3px 0 #00000026;
    transition: all .2s ease;
}

.control::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: all .2s ease;
}

.checkbox:has(> input:checked) .control {
    background-color: #01D501;
}

.checkbox:has(> input:checked) .control::after {
    left: 22px;
}